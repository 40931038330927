<template>
    <button class="btn btn-info py-2" @click="showModal = true">
        <span>{{ btn_text }}
        </span>
    </button>
    <div id="myModal" class="modalmn" v-show="showModal">
        <div class="modal-content">
                <div class="d-flex  align-items-center justify-content-center mt-sm-3">
                    <button class="btn-no description-sm montserrat-bold position-absolute top-0 end-0" @click="showModal = false">X</button>
                </div>
                <div class="card-title montserrat-bold text-uppercase text-info text-center description-lg d-flex align-items-center justify-content-center mt-sm-3 mx-5">
                    {{ title }}
                </div>
                <div class="card-text description-xs montserrat-bold text-center d-flex align-items-center justify-content-center mt-2 m-sm-3 px-md-5" v-html="description">
                </div>
                <div class="card-text title-sm text-info montserrat-bold text-center d-flex align-items-center justify-content-center">
                    {{ price }}
                </div>
                <div class="d-flex  align-items-center justify-content-center mt-sm-3 text-center">
                    <a class="btn-si description-sm montserrat-bold text-decoration-none" :href="`https://wa.me/+525549090484?text=Hola,%20Me%20interesa%20comprar%20el%20paquete%20${title}%20anunciado%20en%20su%20página%20LiveOneHealt.com`" target="_blank">
                    COMPRAR CON UN ASESOR</a>
                </div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from "vue";

    const props = defineProps({
        title: {
            type: String,
            default: "Masaje"
        },
        description: {
            type: String,
        },
        price: {
            required: false,
            type: [String, Number],
        },
        btn_text: {
            default: "MAS INFORMACIÓN",
            type: String
        },
    })

    const showModal = ref(false);
    
</script>
    
<style lang="scss" scoped>
    .modalmn {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
    z-index: 100;
    }
    .modal-content {
    max-width: 60%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff; /* Fondo blanco */
    padding: 20px;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    .btn-no {
    width: 70px;  
    height: 70px;    
    background-color: #7FDAE0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    }
    .btn-si {
    width: 70%;      
    background-color: #7FDAE0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    }
    .btn {
            margin: 20px 0px 10px 0px;
            border-radius: 10em;
            padding: 0.3em 1.5em;

            &:hover {
                color: #000000;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            }
        }
</style>