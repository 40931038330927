<template>
    <component :is="layout || 'section'">
        <router-view></router-view>
    </component>

    <section class="container-fluid border pt-5" v-if="false">
        <div class="row">

            <div class="col-12">
                <span class="title-xl">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod 1
                </span>
            </div>
            <div class="col-12">
                <span class="title-lg">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod 2
                </span>
            </div>
            <div class="col-12">
                <span class="title-md">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                </span>
            </div>
            <div class="col-12">
                <span class="title-sm">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                </span>
            </div>
            <div class="col-12">
                <span class="description-xl">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                </span>
            </div>
            <div class="col-12">
                <span class="description-lg">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                </span>
            </div>

            <div class="col-12">
                <span class="description-md">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                </span>
            </div>
            <div class="col-12">
                <span class="description-sm">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                </span>
            </div>
            <div class="col-12">
                <span class="description-xs">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                </span>
            </div>
            <div class="col-12">
                <span class="description-xxs">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                </span>
            </div>
        </div>
        asd
    </section>
</template>

<script setup>
    // Vue imports
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds
    const layout = computed(() => {
        return router.currentRoute.value.meta.layout
    })

    // Variables

    // Methods
    
</script>

<style lang="scss">
    #app {
        font-family: 'Montserrat-Regular';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2c3e50;
    }
</style>