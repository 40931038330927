import router from 'SRC/router'

export default function redirect(new_route_name, params = null) {
	// console.log(router.currentRoute, 'currentRoute')
	
	if(router.currentRoute.name !== new_route_name) {
		let route_data = {
	    	name: new_route_name,
	    }
	    if(params !== null) {
	    	route_data.params = params
	    }

	    router.push(route_data)
	    window.scrollTo(0, 0)
    }
    else {
    	
    }
}