<template>
        <section class="container py-lg-5 mt-lg-5 pt-5" style="">
            <div class="row d-flex justify-content-center mt-lg-3">
                <div class="col-12 col-lg-5">
                <div class="d-flex justify-content-center justify-content-lg-start">
                    <div class="ps-4 border-left-info mb-4 py-2">
                        <span class="description-md montserrat-semibold">
                            PAQUETES
                        </span>
                    </div>
                </div>
                    <img class="img-top" 
                    :src="img1" alt="main-m" />
                </div>
                <div class="justify-content-center justify-content-end col-12 col-lg-3 mt-lg-4">
                    <img class="img-top-2 w-100" 
                    :src="img2" alt="main-m2" />
                </div>
                <div class="d-flex justify-content-center align-items-baseline col-12 col-lg-4">
                    <img class="img-top-3 img-fluid rounded-circle" 
                    :src="img3" alt="main-m3" />
                </div>
            </div>
        </section>

       <section class="container-fluid cont_pack py-5">
        <div class="row">
            <div class="col-12 text-center mb-5">
                <span class="description-sm text-info montserrat-semibold">
                </span>
                <br>
                <span class="title-md montserrat-semibold text-info">
                    PRECIOS DE PAQUETES
                </span>
            </div>
        </div>


        <div class="row mb-4 mx-5">
            <div class="col-12">
                <div class="container">
                    <div class="row ">
                        <div class="col-12 col-md-6 col-xl-4 mt-2" v-for="(lista, index) in listas" :key="index">
                            <li-card 
                            :img="lista.image && /\.(jpeg|jpg|webp|jp2|gif|png)$/.test(lista.image) ? lista.image : cards[index].img"
                            :title="lista.title"
                            :price="'$' + lista.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :list_Serv="lista.list_item_1"
                            :description="lista.description ? lista.description = undefined : lista.list_item_1 + '<br><br> ' + lista.list_item_2 + '<br><br> ' + lista.list_item_3">
                            
                            <ul class="list-group space title-xxsm montserrat-bold my-2 mx-2 d-flex justify-content-center" style="min-height: 150px;">
                                <li class="list-group-item borderless" >
                                    <img :src="checkImg" class="mx-1"/> {{ lista.list_item_1 }}
                                </li>
                                <li class="list-group-item borderless" >
                                    <img :src="checkImg" class="mx-1" /> {{ lista.list_item_2 }}
                                </li>
                                <li class="list-group-item borderless" >
                                    <img :src="checkImg" class="mx-1" /> {{ lista.list_item_2 }}
                                </li>
                            </ul>
                            <li-modal :title="lista.title"
                                :price="'$' + lista.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                :description="lista.description ? lista.description = undefined : lista.list_item_1 + '<br><br> ' + lista.list_item_2 + '<br><br> ' + lista.list_item_3">
                            </li-modal>
                            </li-card>
                        </div>
                    </div>  
                </div>    
            </div>
        </div>
    </section>

</template>
<script setup>
    // Vue Imports
    import { ref, computed, inject  } from 'vue'


    // Hooks
    
    // Injects

    // Computeds

    // Variables 
    // Images
    let img_1c = require("ASSETS/images/package/Img_Pack")
    let img_2c = require("ASSETS/images/package/Img_Pack3")
    let img_3c = require("ASSETS/images/package/Img_Pack2")
    let img_4c = require("ASSETS/images/package/Img_Pack")
    let img_5c = require("ASSETS/images/package/Img_Pack3")
    let img_6c = require("ASSETS/images/package/Img_Pack2")

    const img1 = require("ASSETS/images/package/Pack_Mn2")
    const img2 = require("ASSETS/images/package/Pack_Mn3")
    const img3 = require("ASSETS/images/package/Pack_Mn")
        
    const checkImg = require("ASSETS/images/services/CheckMn")

        const cards = ref([
        { img: img_1c},
        { img: img_2c},
        { img: img_3c},
        { img: img_4c},
        { img: img_5c},
        { img: img_6c},
        ])
    const listas = ref([])    


    
        const apiUrl = 'https://api-gl.monocilindrero.co/page/prices/get_prices?section=prices_packages';
        const authToken = 'ba+BldFH/xgFGmIV+Z4+Ry94zz1VM8S0lafeRLdxZZYmGygLiNMmwMc+9yxDQrQ+X0gbDg5ke0ZCc6up5W5Q6g==';
        
        const requestOptions = {
        method: 'GET',
        headers: {
            "reference": "com.grupolive.system",
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
        };

        fetch(apiUrl, requestOptions)
        .then(response => {
            if (!response.ok) {
            throw new Error('La solicitud no fue exitosa');
            }
            return response.json();
        })
        .then(datamn => {
            //console.log('Datos:', datamn);

            // variable 
            listas.value = datamn.data.list;
        })
        .catch(error => {
            console.error('Error al consumir la API:', error);
        });

</script>


<style lang="scss" scoped>
    li.borderless {
    border: 0 none;
    text-align: left;
    }
    .space{
        min-height: 120px;
    }

    @media (min-width:992px) {
    .cont_pack {
        margin-top: -30px;
    }
    .img-top-3 {
        margin-top: -50px;
    }
}
    @media (max-width:992px) {
    .img-top {
        width: 100%;
    }
    .img-top-2 {
        margin-top: 10px;
    }
    .img-top-3 {
        margin-top: 10px;
    }
}
@media (min-width:1400px) {
    .img-top {
        margin-left: 50px;
    }
}
</style>

<!--  
    const cards = ref([
        { img: img_1c, title: "MASAJE ANTIESTRÉS",  list_Serv: "2 SESIONES", list_Serv2: "GRATIS CORTE DE CABELLO", list_Serv3: "O GELISH",
        price: "$2,239", btn_text: "REALIZAR TU CITA"},
        { img: img_2c, title: "MASAJE VOLCANICO",  list_Serv: "6 SESIONES", list_Serv2: "CALIDAD", list_Serv3: "CONFIANZA",
        price: "$6,345", btn_text: "REALIZAR TU CITA"},
        { img: img_3c, title: "TRATAMIENTO REDUCTIVO",  list_Serv: "4 SESIONES", list_Serv2: "RESULTADOS", list_Serv3: "CALIDAD",
        price: "$11,000", btn_text: "REALIZAR TU CITA"}
    ])
-->