<template>
    <div class="container py-5">
        <div class="row d-flex justify-content-center px-lg-5">
            <div class="col-12 col-lg-7 mt-lg-5">
                <div class="d-flex justify-content-center justify-content-lg-start">
                <div class="contact-text ps-4 border-left-info mb-4 py-2 mt-lg-5">
                    <span class="title-sm">
                        CONTACTO</span>
                </div>
            </div>
                <div class="img_contact">
                    <img :src="img1"
                        class="img-top img-fluid" 
                        alt="main-m"/>
                </div>
            </div>
            <div class="col-12 col-lg-5 d-flex justify-content-center align-self-baseline mt-2" 
                style="margin-right: -60px;">
                
                <img :src="img2"
                    class="img-top-3 img-fluid rounded-circle" 
                    alt="main-m3" />
            </div>
        </div>
    </div>

    <div class="container my-5">
        <div class="row d-flex justify-content-around">
            <div class="col-12 col-md-6">
                <section style="max-width: 600px;">
                    <div class="mb-4">    
                        <span class="description-sm text-info montserrat-semibold">
                            CONTÁCTANOS
                        </span>
                    </div>

                    <div class="mb-3">    
                        <span class="title-sm">
                            Ponte en contacto con nosotros.
                        </span>
                    </div>

                    <div class="mb-5">
                        <span class="montserrat-light description-xs">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Omnis maxime vel dolores iusto ipsam odio consectetur,
                        </span>
                    </div>
                    <div class="mb-3">
                        <span class="montserrat-light description-xs"><img :src="icon1">
                            contacto@LIVEONEHEALTTH
                        </span>
                    </div>
                    <div class="mb-3">
                        <span class="montserrat-light description-xs"><img :src="icon2">
                            55 5088 6240
                        </span>
                    </div>
                    <div class="mb-3">
                        <span class="montserrat-light description-xs"><img :src="icon2">
                            55 4909 0484
                        </span>
                    </div>
                    <div class="mb-5">
                        <span class="montserrat-light description-xs"><img :src="icon3">
                            Pedro Romero de Terreros 1211, 
                            <br>&nbsp; &nbsp; &nbsp; &nbsp;Navarte Poniente, Benito Juarez,  
                            <br>&nbsp; &nbsp; &nbsp; &nbsp;03020 Ciudad de Mexico, CDMX.
                        </span>
                    </div>
                </section>
            </div>
            <div class="col-12 col-md-6" v-if="!sended_email">
                <div class="row justify-content-between">
                    <div class="col-6">
                        <label class="form-label" for="typeText">Nombre</label>
                        <input v-model="name" type="text" class="form-control mb-3" />
                    </div>

                    <div class="col-6">
                        <label class="form-label" for="typeEmail">Email</label>
                        <input v-model="email" type="email" class="form-control mb-3" />
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-6">
                        <label class="form-label" for="typeNumber">Móvil</label>
                        <input v-model="phone" type="number" class="form-control mb-3" />
                    </div>

                    <div class="col-6">
                        <label class="form-label" for="typeText">Asunto</label>
                        <input v-model="subject" type="text" class="form-control mb-3" />
                    </div>
                </div>
                <div class="form-outline">
                    <label class="form-label" for="textAreaExample">Mensaje</label>
                    <textarea v-model="message" class="form-control" rows="10"></textarea>
                </div>
                <div class="my-5">
                    <button @click="sendMail()" 
                        class="btn btn-info btn-pill px-4 py-3">
                            ENVIAR MENSAJE
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6" v-else>
                <div class="description-sm text-info montserrat-semibold">
                    TU INFORMACION FUE ENVIADA
                </div>
                <div class="description-sm montserrat-semibold">
                    Pronto se pondrán en contacto contigo 
                </div>        
            </div>
        </div>
    </div>
    <div class="container my-3 p-0">
        <div class="row m-0 p-0">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.424223720009!2d-99.15793752750083!3d19.3940682279434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff0f9fd9ab87%3A0xcc8bf57fc76d4d5!2sPedro%20Romero%20de%20Terreros%201211%2C%20Narvarte%20Poniente%2C%20Benito%20Ju%C3%A1rez%2C%2003020%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1692663215251!5m2!1ses-419!2smx" 
                width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>    
        </div>
    </div>
</template>

<script setup>
    // Images
    let img_main = require("ASSETS/images/home/main")
    let img_1 = require("ASSETS/images/home/1")
    let img_2 = require("ASSETS/images/home/2")
    let img_person = require("ASSETS/images/us/person")

    let img_team1 = require("ASSETS/images/us/team/1")
    let img_team2 = require("ASSETS/images/us/team/2")
    let img_team3 = require("ASSETS/images/us/team/3")
    let img_team4 = require("ASSETS/images/us/team/4")

    const img1 = require("ASSETS/images/contact/ContactMn2")
    const img2 = require("ASSETS/images/contact/ContactMn1")

    const icon1 = require("ASSETS/images/contact/IconEmail")
    const icon2 = require("ASSETS/images/contact/IconPhone")
    const icon3 = require("ASSETS/images/contact/IconMaps")

    // Vue Imports
    import { ref, reactive, computed, inject, onBeforeMount } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"
    

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Inject

    // Variables
    const rates = ref([
        {
            value: '764+',
            text: 'Clientas Satisfechas',
        },
        {
            value: '10',
            text: 'Expertos especializados',
        },
        {
            value: '122',
            text: 'Opiniones',
        },
        {
            value: '20',
            text: 'Servicios',
        }
    ])

    const cards_content = ref([
        { title: 'asdasd1', description: 'aasdasdasdasdasdasdasdasdasd'},
        { title: 'asdasd2', description: 'aasdasdasdasdasdasdasdasdasd'},
        { title: 'asdasd3', description: 'aasdasdasdasdasdasdasdasdasd'}
    ])

    const card_team = ref([
        { img: img_team1, name: 'Marisol Tapia', job: 'Terapeuta' },
        { img: img_team2, name: 'Brenda Sánchez', job: 'Masajista' },
        { img: img_team3, name: 'Blanca Reyes', job: 'Cosmetóloga' },
        { img: img_team4, name: 'Liliana Luna', job: 'Maquillista Profesional' }
    ])

    const name = ref(null)
    const phone = ref(null)
    const email = ref(null)
    const subject = ref(null)
    const message = ref(null)
    const sended_email = ref(false)

    // Methods
    const sendMail = () => {
        let body = JSON.stringify({ 
            name: name.value, 
            phone: phone.value, 
            email: email.value,
            subject: subject.value, 
            message: message.value 
        })
        
        fetch('https://xkratz.com/send_email.php', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, /',
                'Content-Type': 'application/json'
            },
            body
        }).then(
            res => {
                res.json()
                console.log(res, 'RES')

                if(RES.status == 200) {
                    sended_email.value = true
                }
            }
        ).then(
            res => console.log(res)
        )
    }
</script>
<style lang="scss" scoped>
    @media (min-width:992px) {
    .contact-text {
        margin-left: -50px;
    }
}
</style>



