<template>
    <li-navbar></li-navbar>

    <slot></slot>

    <section class="container">
        
        <div class="bg-info rounded-30 px-2 py-3">

            <li-slider _id="slider_images" 
                :items="gallery" 
                :items_per_page="5"
                :autoplay="true"
                :show_dots="false">

                <template v-slot:content-image="data">
                    <div class="px-2">
                        <img :src="data.value" class="img-fluid rounded-30">
                    </div>
                </template>

            </li-slider>

        </div>

    </section>

    <li-footer></li-footer>
</template>

<script setup>
    // Images
    const gallery_1 = require("ASSETS/images/gallery/1")
    const gallery_2 = require("ASSETS/images/gallery/2")
    const gallery_3 = require("ASSETS/images/gallery/3")
    const gallery_4 = require("ASSETS/images/gallery/4")
    const gallery_5 = require("ASSETS/images/gallery/5")
    const gallery_6 = require("ASSETS/images/gallery/6")
    const gallery_7 = require("ASSETS/images/gallery/7")
    const gallery_8 = require("ASSETS/images/gallery/8")
    const gallery_9 = require("ASSETS/images/gallery/9")
    const gallery_10 = require("ASSETS/images/gallery/10")
    const gallery_11 = require("ASSETS/images/gallery/11")
    const gallery_12 = require("ASSETS/images/gallery/12")
    const gallery_13 = require("ASSETS/images/gallery/13")
    const gallery_14 = require("ASSETS/images/gallery/14")

    // Vue imports
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds

    // Variables
    const gallery = ref([ gallery_1, gallery_2, gallery_3, gallery_4, gallery_5, gallery_6, gallery_7, gallery_8, gallery_9, gallery_10, gallery_11, gallery_12, gallery_13, gallery_14
    ])

    // Methods

</script>


<style lang="scss">
    @import 'ASSETS/scss/minxin.scss';
</style>