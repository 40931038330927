<template>
    <section class="container">
        <div class="row d-flex align-items-center justify-content-around mt-5 mx-lg-5">
            <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-start">
                <div class="contact-text ps-4 border-left-info mb-lg-5 py-2">
                    <span class="title-sm">
                        NOSOTROS
                    </span>
                </div>
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <img class="img-c img-fluid rounded-circle" 
                :src="img_mnc" alt="main-m3" />
            </div>
        </div>
    </section>

    <section class="container cont_us py-5">
        <div class="row mb-lg-5 mx-lg-3">
            <div class="col-12 col-md-6" v-if="true">
                <div class="img_us" style="height: 100%; max-width: 600px; position: relative;">
                    <div style="width: 100%; max-width: 260px; position: absolute; right: 0px; top: 0px; z-index: 2;">
                        <img :src="img_mn2" class="img-fluid rounded-30">
                    </div>
                    
                    <div style="width: 100%; max-width: 400px; z-index: 1;">
                        <img :src="img_mn" class="img-fluid rounded-30 mt-5 pt-5">
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 py-5 mt-lg-5 px-5">
                
                <div class="mt-lg-5 pt-5">
                    <span class="text-info description-xs montserrat-semibold">
                        Acerca de
                    </span>
                </div>
                <div class="mb-5 pb-lg-5">
                    <span class="title-sm">
                        Nosotros
                    </span>
                </div>

                <div class="ps-4 border-left-info mb-5 py-2">
                    <span class="description-xs">
                        Ofrecemos un concepto familiar y minimalista. 
                        Ofrecemos masajes relajantes, faciales y sesiones
                        de relajación. Tienen un duración promedio de 20
                        minutos hasta 2 horas. Nuestras experiencias pueden
                        disfrutarse de forma individual o en pareja.
                    </span>
                </div>

                <div>
                    <button class="btn btn-info btn-pill px-4 py-3 mt-lg-5 " onclick="location.href='https://wa.link/0mymun';">
                        Realiza tu cita
                    </button>
                </div>
            </div>
        </div>
    </section>

    <section class="container-fluid bg-info py-5">
        <div class="row">
            <div class="col-12 text-center mb-5">
                <span class="description-md">
                    EQUIPO
                </span> <br>
                <span class="title-md">
                    Nuestras expertas
                </span>
            </div>
        </div>
        
        <div class="row mb-4 ">
            <div class="col-12">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-3" v-for="(person, i_person) in card_team" :key="i_person">
                            <div class="text-center rounded-20 mb-3 mb-lg-0" 
                                style="background-color: white;
                                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;">
                                            
                                        <img :src="person.img" class="w-100"
                                            style="border-top-left-radius: 20px; border-top-right-radius: 20px;">

                                        <div class="pt-3 pb-2">
                                            <span class="description-sm">{{ person.name }}</span>
                                        </div>
                                            
                                        <div class="flex-center">
                                            <div class="bg-info" style="height: 3px; width: 100%; max-width: 200px;"></div>
                                        </div>

                                        <div class="pt-2 pb-3">
                                            <span class="description-xs">{{ person.job }}</span>
                                        </div>
                            </div>
                        </div>
                    </div>  
                </div>    
            </div>
        </div>
    </section>

    <section class="container p-5 mb-4">
        <li-experience-card variant="info" 
            :photo_person="img_person"
            name_person="Fer Flores"
            job_person="Lic. en Artes"
            comment_person="Excelente lugar y el trato de maravilla muchas gracias llegué un poco tarde y aún así respetaron mi tiempo, sin duda alguna si regresaremos muchas gracias">
            
        </li-experience-card>
    </section>

    <section class="container-fluid bg-info py-0 py-xl-5 mb-5">
        <div class="row flex-center h-100">
            <div class="col-12 col-sm-6 col-md-3 text-center py-5" 
                v-for="(rate, i_rate) in rates" :key="i_rate">
                    <span class="title-sm montserrat-black">
                        {{ rate.value }}
                    </span> <br>
                    <span class="description-sm">
                        {{ rate.text }}
                    </span>
            </div>
        </div>
    </section>
</template>

<script setup>
    // Images
    const img_mn = require("ASSETS/images/us/img_us_2")
    const img_mn2 = require("ASSETS/images/us/img_us_3")
    const img_mnc = require("ASSETS/images/us/img_us_c")

    let img_main = require("ASSETS/images/home/main")
    let img_1 = require("ASSETS/images/home/1")
    let img_2 = require("ASSETS/images/home/2")
    let img_person = require("ASSETS/images/us/person")

    let img_team1 = require("ASSETS/images/us/team/1")
    let img_team2 = require("ASSETS/images/us/team/2")
    let img_team3 = require("ASSETS/images/us/team/3")
    let img_team4 = require("ASSETS/images/us/team/4")

    // Vue Imports
    import { ref, reactive, computed, inject, onBeforeMount } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Inject

    // Variables
    const rates = ref([
        {
            value: '764+',
            text: 'Clientas Satisfechas',
        },
        {
            value: '10',
            text: 'Expertos especializados',
        },
        {
            value: '122',
            text: 'Opiniones',
        },
        {
            value: '20',
            text: 'Servicios',
        }
    ])

    const cards_content = ref([
        { title: 'asdasd1', description: 'aasdasdasdasdasdasdasdasdasd'},
        { title: 'asdasd2', description: 'aasdasdasdasdasdasdasdasdasd'},
        { title: 'asdasd3', description: 'aasdasdasdasdasdasdasdasdasd'}
    ])

    const card_team = ref([
        { img: img_team1, name: 'Marisol Tapia', job: 'Terapeuta' },
        { img: img_team2, name: 'Brenda Sánchez', job: 'Masajista' },
        { img: img_team3, name: 'Blanca Reyes', job: 'Cosmetóloga' },
        { img: img_team4, name: 'Liliana Luna', job: 'Maquillista Profesional' }
    ])

    // Methods
</script>

<style lang="scss" scoped>
    @media (min-width:992px) {
    .cont_us {
        margin-top: -160px;
    }
    .img_us{
        margin-left: 20px;
    }
    .contact-text{
        margin-top: -60px;
    }
}
</style>



