import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// global.CONFIG = require('./page.config.js')

// import BOOTSTRAP
import 'ASSETS/scss/bootstrap/bootstrap.scss'
// import CUSTOM
import 'ASSETS/scss/custom.scss'


// imports LIBS
// import _Request from './libs/sendRequest.js'
import _redirect from './libs/redirect.js'


// import GLOBAL COMPONENTS
import { Navbar, Card, ExperienceCard, Footer, Slider, Modal } from './components/index.js'



// TEMPLATES
import Tdefault from 'TEMPLATES/Tdefault'
import Tcontact from 'TEMPLATES/Tcontact'



// Create App
const app = createApp(App)
app.use(store)
app.use(router)

// Templates
app.component('default', Tdefault)
app.component('contact', Tcontact)

// Components
app.component('li-navbar', Navbar)
app.component('li-card', Card)
app.component('li-experience-card', ExperienceCard)
app.component('li-footer', Footer)
app.component('li-slider', Slider)
app.component('li-modal', Modal)

// libs
// app.provide('$sendRequest', _Request)
app.provide('$redirect', _redirect)

//mount
app.mount('#app')