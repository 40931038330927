<template>
    <section class="template-contact">
        <li-navbar style="z-index: 1;"></li-navbar>

        <slot></slot>
        
        <li-footer></li-footer>
    </section>
</template>

<script setup>
    // Images

    // Vue imports
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds

    // Variables

    // Methods

</script>


<style lang="scss">
    @import 'ASSETS/scss/minxin.scss';
</style>