import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Us from '../views/Us.vue'
import Services from '../views/Services.vue'
import Contact from '../views/Contact.vue'
import Packages from '../views/Packages.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            layout: 'default'
        },
        component: Home
    },
    {
        path: '/nosotros',
        name: 'Us',
        meta: {
            layout: 'default'
        },
        component: Us
    },
    {
        path: '/servicios',
        name: 'Services',
        meta: {
            layout: 'default'
        },
        component: Services
    },
    {
        path: '/paquetes',
        name: 'Packages',
        meta: {
            layout: 'default'
        },
        component: Packages
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            layout: 'contact'
        },
        component: Contact
    },
    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router