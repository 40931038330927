<template>
    <footer class="li-footer flex-center">
        <div class="w-100 mw-footer">

            <div class="row flex-between-start pb-0 mb-2 pb-xl-5 mb-xl-4" 
                style="border-bottom: 1px solid #FFFFFF;">

                <div class="col-12 col-lg-5 col-xl-3 d-flex justify-content-center d-lg-block mb-0 mb-lg-5">
                    <img :src="img_logo" class="mb-5">

                    <div class="flex-start" v-if="false">
                        <a href="https://www.facebook.com/tonyalmazanofficial" target="_blank">
                            <div class="mr-2" style="width: 20px;">
                                <img :src="img_fb" class="img-fluid">
                            </div>
                        </a>
                        <a href="https://www.youtube.com/user/inversiones123web" target="_blank">
                            <div class="mx-2" style="width: 20px;">
                                <img :src="img_yt" class="img-fluid">
                            </div>
                        </a>
                        <a href="https://www.instagram.com/tonyalmazanofficial/" target="_blank">
                            <div class="mx-2" style="width: 20px;">
                                <img :src="img_ig" class="img-fluid">
                            </div>
                        </a>
                    </div>
                </div>

                <div class="d-none d-xl-block col-xl-2 mb-4 mb-lg-0">
                </div>

                <div class="col-sm-6 col-lg-3 col-xl-2 mb-4 mb-lg-0">
                    <div class="title">
                        <span class="montserrat-bold">
                            Live One Healt
                        </span>
                    </div>

                    <div class="mt-3 mb-2">
                        <span class="montserrat-regular description-xxs">
                            Inicio
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="montserrat-regular description-xxs">
                            Nosotros
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="montserrat-regular description-xxs">
                            Servicios
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="montserrat-regular description-xxs">
                            Contacto
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="montserrat-regular description-xxs">
                            FAQ
                        </span>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4 col-xl-3 mb-4 mb-lg-0">
                    <div class="title">
                        <span class=" montserrat-bold">
                            Información
                        </span>
                    </div>

                    <div class="mt-3 mb-2">
                        <span class="montserrat-regular description-xxs">
                            contacto@liveonehealth.com
                        </span>
                    </div>
                     
                    <div class="my-2">
                        <span class="montserrat-regular description-xxs">
                            55 5088 6240
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="montserrat-regular description-xxs">
                            55 4909 0484
                        </span>
                    </div>

                    <div class="my-2">
                        <span class="montserrat-regular description-xxs">
                            Pedro Romero de Terreros 1211, 
                            Narvarte Poniente, Benito Juárez, 
                            03020 Ciudad de México, CDMX
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 flex-start-center order-2 order-md-1">
                    <span class="montserrat-regular description-xs">
                        Desarrollado por <span class="montserrat-semibold">monocilindrero.com</span>
                    </span>
                </div>

                <div class="col-12 col-md-6 flex-end-center order-1 order-md-2 mb-4 mb-md-0">
                    <span class="montserrat-regular description-xs">
                        <img :src="icon_arrow_up"> Regresar arriba
                    </span>
                </div>
            </div>
        </div>
        
    </footer>
</template>

<script setup>
    // Images
    let img_fb = require("ASSETS/images/footer/fb")
    let img_ig = require("ASSETS/images/footer/ig")
    let img_yt = require("ASSETS/images/footer/yt")
    let img_logo = require("ASSETS/images/footer/logo")

    let icon_arrow_up = require("ASSETS/icons/arrow_up")

    // Vue Imports
    import { ref, reactive, computed, inject, onBeforeMount } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Inject

    // Variables
    const rates = ref([
        {
            value: '764+',
            text: 'Clientas Satisfechas',
        },
        {
            value: '10',
            text: 'Expertos especializados',
        },
        {
            value: '122',
            text: 'Opiniones',
        },
        {
            value: '20',
            text: 'Servicios',
        }
    ])

    // Methods
    const redirect = (name_route) => {
        if(this.$route.name !== name_route) {
            window.scrollTo(0, 0)

            this.$router.push({
                name: name_route
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/minxin.scss';

    .li-footer {
        padding-top: 100px;
        padding-bottom: 20px;

        .title {
            font-size: 20px;
            border-bottom: 1px solid white;
            padding-bottom: 10px;
        }

        .mw-footer {
            max-width: 300px;

            @include upPixels(460px) {
                max-width: 400px;
            }
            @include upPixels(576px) {
                max-width: 520px;
            }
            @include upPixels(768px) {
                max-width: 600px;
            }
            @include upPixels(992px) {
                max-width: 820px;
            }
            @include upPixels(1200px) {
                max-width: 1170px;
            }
        }
    }
</style>