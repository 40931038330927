<template>
    <section class="container pt-5">
        <div class="row">
            <div class="col-12 col-md-6 flex-center-start">
                <section style="max-width: 600px;">
                    <div style="max-width: 400px;">
                        <span class="title-sm">
                            Bienvenida a Live One Health
                        </span>
                    </div>
                    <div class="mb-5">
                        <span class="montserrat-light description-lg">
                            Belleza · Salud · Transformación
                        </span>
                    </div>

                    <div class="mb-2">
                        <span class="montserrat-light description-xs">
                            SOMOS EL MEJOR CONCEPTO DE SPA URBANO EN CDMX.
                            NUESTRA MISIÓN EN LIVE ONE HEALTH ES CONSENTIRTE Y RELAJARTE.
                        </span>
                    </div>
                    <div>
                        <button class="btn btn-info btn-pill px-4 py-2" @click="$redirect('Us')">
                            CONÓCENOS...
                        </button>
                    </div>
                </section>
            </div>
            <div class="col-12 col-md-6">
                <img :src="img_main" class="img-fluid">
            </div>
        </div>
    </section>

    <section class="container py-lg-5 my-3">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="cont-home-img">
                    <div class="home-img1 flex-center">
                        <img :src="img_1" class="img-fluid rounded-30">
                    </div>
                    
                    <div class="home-img2" >
                        <img :src="img_2" class="img-fluid rounded-30">
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 py-5">
                
                <div class="">
                    <span class="text-info description-xs montserrat-semibold">
                        Conócenos
                    </span>
                </div>
                <div class="mb-4">
                    <span class="title-sm">
                        Acérca de Live One Health
                    </span>
                </div>

                <div class="ps-4 border-left-info mb-4 py-2">
                    <span class="description-xs">
                        Ven a Live One Health y encontrarás un espacio para conectarte contigo y olvidar el estrés. <br><br>

                        Nuestro equipo de terapeutas están altamente calificadas para brindarte cada uno de los diversos tratamientos que ofrecemos.
                    </span>
                </div>

                <div>
                    <button class="btn btn-info btn-pill px-4 py-3" @click="$redirect('Contact')">
                        Realiza tu cita
                    </button>
                </div>
            </div>
        </div>
    </section>

    <section class="container-fluid bg-info py-0 py-xl-5">
        <div class="row flex-center h-100">
            <div class="col-12 col-sm-6 col-md-3 text-center py-5" 
                v-for="(rate, i_rate) in rates" :key="i_rate">
                    <span class="title-sm montserrat-black">
                        {{ rate.value }}
                    </span> <br>
                    <span class="description-sm">
                        {{ rate.text }}
                    </span>
            </div>
        </div>
    </section>

    <section class="container py-5">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <span class="text-info montserrat-semibold">
                    NUESTROS SERVICIOS
                </span> <br>
                <span class="title-sm">
                    Tratamientos
                </span>
            </div>
        </div>

        <div class="row flex-center mb-4">
            <!-- Card -->
            <div class="col-12 col-md-6 col-lg-4 mt-2" 
                v-for="(list2, index) in lists2" :key="index">

                <li-card 
                    :title="list2.title"
                    :text="list2.short_description"
                    :img="list2.image && /\.(jpeg|jpg|webp|jp2|gif|png)$/.test(list2.image) ? list2.image : cards[index].img"
                    :description="list2.description ? list2.description : list2.short_description">
                    <li-modal 
                    :title="list2.title"
                    :price="list2.price !== null && list2.price !== 0 && list2.price !== '' ? ('$' + list2.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : ''"
                    :description="list2.description ? list2.description : list2.short_description">
                    </li-modal>    
                </li-card>
            </div>
            <!-- EndCard -->
        </div>
    </section>

    <section class="container-fluid bg-info py-5">
        <div class="row">
            <div class="col-12 text-center mb-5">
                <span class="title-md text-white">
                    Promociones del mes
                </span>        
            </div>
        </div>
        
        <div class="row mb-4">
            <div class="col-12">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 mt-2" v-for="(list, index) in lists" :key="index">
                            <li-card :img="list.image && /\.(jpeg|jpg|webp|jp2|gif|png)$/.test(list.image) ? list.image : cards[index].img" 
                            :title="list.title"
                            :price="list.price !== null && list.price !== 0 && list.price !== '' ? ('$' + list.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : ''"
                            :list_Serv="list.list_Serv"
                            :description="list.description ? list.description = undefined : list.list_item_1 + '<br><br> ' + list.list_item_2 + '<br><br> ' + list.list_item_3">
                            <ul class="list-group title-xxsm montserrat-bold my-4 mx-3">
                                <li class="list-group-item borderless" >
                                    <img :src="checkImg" class="mx-1"/> {{ list.list_item_1 }}
                                </li>
                                <li class="list-group-item borderless" >
                                    <img :src="checkImg" class="mx-1" /> {{ list.list_item_2 }}
                                </li>
                                <li class="list-group-item borderless" >
                                    <img :src="checkImg" class="mx-1" /> {{ list.list_item_3 }}
                                </li>
                            </ul>
                                <li-modal :title="list.title"
                                    :price="'$' + list.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                    :description="list.description ? list.description = undefined : list.list_item_1 + '<br><br> ' + list.list_item_2 + '<br><br> ' + list.list_item_3">
                                </li-modal>
                            </li-card>
                        </div>
                    </div>  
                </div>    
            </div>
        </div>
    </section>

    <section class="container p-5 mb-4">
        <li-experience-card variant="info" 
            :photo_person="img_person"
            name_person="Cecilia Gomez"
            job_person="Maestra"
            comment_person="Increíble! He ido a la radiofrecuencia facial y la recomiendo ampliamente, ahora pruebo el área de spa y estoy encantada lo recomiendo, super atención y buenísima la terapeuta.">
        </li-experience-card>
    </section>
</template>

<script setup>
    // Images
    let img_main = require("ASSETS/images/home/main")
    let img_1 = require("ASSETS/images/home/1")
    let img_2 = require("ASSETS/images/home/2")
    let img_person = require("ASSETS/images/home/person")

    const checkImg = require("ASSETS/images/services/CheckMn")

    let img_1c = require("ASSETS/images/home/CardHome1")
    let img_2c = require("ASSETS/images/home/CardHome2")
    let img_3c = require("ASSETS/images/home/CardHome3")

    let img_home = require("ASSETS/images/home/imgHome")
    let img_home2 = require("ASSETS/images/home/imgHome2")
    let img_home3 = require("ASSETS/images/home/imgHome3")

    // Vue Imports
    import { ref, reactive, computed, inject, onBeforeMount } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

                // Hooks
    const router = useRouter()
    const store = useStore()

    // Inject
    const $redirect = inject('$redirect')


    // Props

    // Variables
    const nav = ref(false)
    const rates = ref([
        {
            value: '764+',
            text: 'Clientas Satisfechas',
        },
        {
            value: '10',
            text: 'Expertos especializados',
        },
        {
            value: '122',
            text: 'Opiniones',
        },
        {
            value: '20',
            text: 'Servicios',
        }
    ])

    const cards = ref([
        { img: img_1c},
        { img: img_2c},
        { img: img_3c},
        { img: img_1c},
        { img: img_2c},
        { img: img_3c},
    ])

    const cards_home = ref([
        {image: img_home},
        {image: img_home2},
        {image: img_home3},
        {image: img_home},
        {image: img_home2},
        {image: img_home3},

    ])

        const lists = ref([])  
 
        const apiUrl = 'https://api-gl.monocilindrero.co/page/prices/get_prices?section=prices_home';
        const authToken = 'ba+BldFH/xgFGmIV+Z4+Ry94zz1VM8S0lafeRLdxZZYmGygLiNMmwMc+9yxDQrQ+X0gbDg5ke0ZCc6up5W5Q6g==';
        
        const requestOptions = {
        method: 'GET',
        headers: {
            "reference": "com.grupolive.system",
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
        };

        fetch(apiUrl, requestOptions)
        .then(response => {
            if (!response.ok) {
            throw new Error('La solicitud no fue exitosa');
            }
            return response.json();
        })
        .then(datamn => {
            //console.log('Datos:', datamn);

            // variable 
            lists.value = datamn.data.list;
        })
        .catch(error => {
            console.error('Error al consumir la API:', error);
        });

        //API2.........................................
        
    const lists2 = ref([])  
 
    const apiUrl2 = 'https://api-gl.monocilindrero.co/page/prices/get_prices?section=treatments_home';
    const authToken2 = 'ba+BldFH/xgFGmIV+Z4+Ry94zz1VM8S0lafeRLdxZZYmGygLiNMmwMc+9yxDQrQ+X0gbDg5ke0ZCc6up5W5Q6g==';
    
    const requestOptions2 = {
    method: 'GET',
    headers: {
        "reference": "com.grupolive.system",
        'Authorization': `Bearer ${authToken2}`,
        'Content-Type': 'application/json',
    },
    };

    fetch(apiUrl2, requestOptions)
    .then(response => {
        if (!response.ok) {
        throw new Error('La solicitud no fue exitosa');
        }
        return response.json();
    })
    .then(datamn => {
        //console.log('Datos:', datamn);

        // variable 
        lists2.value = datamn.data.list;
    })
    .catch(error => {
        console.error('Error al consumir la API:', error);
    });

        

        
</script>
<style lang="scss" scoped>
    li.borderless {
        border: 0 none;
        text-align: left;
    }
    @media (min-width:992px) {
    .cont-home-img{
        position: relative;
        height: 100%;
        max-width: 600px; 
        
    }
    .home-img1{
        position: absolute; 
        width: 100%; 
        max-width: 260px; 
        z-index: 2;
    }
    .home-img2{
        position: absolute; 
        width: 100%; 
        max-width: 400px; 
        
        right: 0px; 
        bottom: 0px; 
        z-index: 1;
    }
 }
 @media (max-width:992px) {
    .cont-home-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    }
    .home-img1{
        margin-top: 0px;
    }
    .home-img2{
        margin-top: 15px;
    }
 }
</style>


