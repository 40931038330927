<template>
	<div class="exp-card bg-secondary flex-center position-relative rounded-30 mt-5 p-4 p-md-5">

		<!-- Decorative circle -->
        <div :class="`content-circle border-bottom-${props.variant}`">
            <div :class="`circle bg-${props.variant}`">
            </div>
        </div>
        	
        <!-- Content Card -->
        <section>
            	
            <!-- Comment Person -->
            <div style="max-width: 820px; margin-top: 80px; margin-bottom: calc(80px - 2rem);">
                <span class="description-lg"><i>
                    {{ comment_person }}
                </i></span>    
            </div>

            <div class="container-fluid">
                <div class="row">

                	<!-- Final line -->
                    <div class="col-12 flex-center final-line mb-3">
                        <div class="line-part-1"></div>
                        <div :class="`line-part-2 bg-${props.variant}`"></div>

                        <div style="width: 20px">
                            <div :class="`line-part-3 
                            	border-bottom-${props.variant} 
                            	border-right-${props.variant} 
                            	border-top-secondary 
                            	border-left-secondary`">
                            </div>
                        </div>
                        
                        <div :class="`line-part-4 bg-${props.variant}`"></div>
                        <div class="line-part-5"></div>
                    </div>

                    <!-- Person information -->
                    <div class="col-12 flex-center">
                        <div style="width: 100%; max-width: 360px;" class=" align-middle">
                            <div class="float-start me-3"
                                style="max-width: 84px">
                                    <img :src="photo_person" class="img-fluid">    
                            </div>
                            <div class="flex-center" 
                                style="height: 84px !important; max-width: 250px;">

                                <div>
                                    <span class="description-md">
                                        {{ name_person }} 
                                    </span><br>
                                    <span class="description-xs"><i>
                                        {{ job_person }}
                                    </i></span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </div>
</template>

<script setup>
    // Vue Imports
    import { ref, reactive, computed, inject, onBeforeMount } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Inject

    // Props
    const props = defineProps({
        variant: {
            required: false,
            type: String,
        	default: 'info'
        },
        photo_person: {
            required: true,
            type: String
        },
        name_person: {
            required: true,
            type: String
        },
        job_person: {
            required: true,
            type: String
        },
        comment_person: {
            required: true,
            type: String
        }
    })

    // Variables

    // Methods

</script>


<style lang="scss" scoped>
    .exp-card {
    	.content-circle {
    		position: absolute;
    		height: 8rem; 
            width: 6rem;
    		inset: -3rem calc(50% - 3rem) calc(50% - 3rem); 

    		.circle {
	        	height: 6rem; 
	        	width: 6rem; 
	        	border-radius: 100%;
	        }
    	}

    	.final-line {
	    	.line-part-1, .line-part-5 {
	    		background-color: #CCC;
	    		max-width: 180px;
	    		width: 100%;
	    		height: 3px;
	    	}

	    	.line-part-2, .line-part-4 {
	    		max-width: 40px; 
	    		width: 100%; 
	    		height: 3px;
	    	}

	    	.line-part-3 {
	    		transform: rotate(45deg);
	    		width: 20px; 
	    		height: 20px; 
	            border-top-left-radius: 100%;
	            border-top-right-radius: 0px;
	            border-bottom-left-radius: 0px;
	            border-bottom-right-radius: 0px;
	            margin-top: -3px;
	    	}
        }
    }
</style>