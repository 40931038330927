<template >
    <nav class="nav-fixed bg-white shadow"
         style="position: sticky; top: 20px; z-index 10;">
        <div class="container" >
            <div class="row">
                <div class="col-12 flex-between-center">
                    <div class="flex-center">
                        <div class="logo-nav">
                            <img :src="logoSrc" 
                                class="img-fluid" style="max-width: 65px;" />
                        </div>
                        <input id="menu-toggle" type="checkbox">
                        <div class="menu">
                            <li :class="`item-nav description-xs montserrat-semibold 
                                    ${(name_route == button.path_name) ? 'text-info' : 'text-black'}`" 
                                v-for="button in buttons"
                                :key="button.text">
                                    <a style="cursor: pointer" 
                                        @click="navigationMenu(button.path_name)"
                                        class="nav-link px-2"
                                        :class="(button.showmobile) ? 'd-block d-lg-none' : 'd-lg-block'" >

                                        {{ button.text }}
                                    </a>
                            </li>
                        </div>
                    </div>

                    <div class="item-nav-btn">
                        <button class="nav-btn-contact d-none d-lg-block btn btn-info btn-pill px-4 montserrat-semibold"
                            @click="$redirect('Contact')">
                            <div class="text-style">CONTACTO</div>
                        </button>
                    </div>

                    <!-- <input id="menu-toggle" type="checkbox"> -->
                    <label class='menu-button-container' for="menu-toggle">
                        <div class='menu-button'></div>
                    </label>
                    
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
    // Images
    const logoSrc = require("@/assets/images/logo 1.png");

    // Vue Imports
    import { ref, computed, inject } from "vue";
    import { useRouter } from 'vue-router'

    // Hooks
    const router = useRouter()

    // Injects
    const $redirect = inject('$redirect')

    // Computeds
    const name_route = computed(() => {
        return router.currentRoute.value.name
    })

    // Variables
    const buttons = ref([
        { text: "INICIO", path_name: "Home", showmobile: false },
        { text: "NOSOTROS", path_name: "Us", showmobile: false },
        { text: "SERVICIOS", path_name: "Services", showmobile: false },
        { text: "PAQUETES", path_name: "Packages", showmobile: false },
        { text: "CONTACTO", path_name: "Contact", showmobile: true },
    ])

    // Methods
    const navigationMenu = (name_route) => {
        let checkbox_menu = document.getElementById('menu-toggle')

        if(checkbox_menu.checked) {
            checkbox_menu.checked = false
        }

        $redirect(name_route)
    }

    
</script>

<style lang="scss" scoped>
    .nav-fixed {
        z-index: 20;
    }

    .logo-nav .img-f {
        max-width: 60px;

    }

    .nav-btn-contact:hover {
        background-color: white;
    }

    .text-style:hover{
            color: #80dadf;
  
    }

    .top-nav {
        display: flex;
        height: 70px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(255, 255, 255);
        margin-top: 20px;
        position: sticky;
    }

    .menu {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .menu>li {
        margin: 0 1rem;
        overflow: hidden;
    }

    .nav-link:hover {
        color: #80dadf;
    }

    .menu-button-container {
        display: none;
        height: 50px;
        width: 70px;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #menu-toggle {
        display: none;
    }

    .menu-button,
    .menu-button::before,
    .menu-button::after {
        display: block;
        background-color: #80dadf;
        position: absolute;
        height: 5px;
        width: 50px;
        transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: 5px;
    }
    .menu-button::before {
        content: '';
        margin-top: -9px;
    }

    .menu-button::after {
        content: '';
        margin-top: 9px;
    }

    @media (max-width: 992px) {
        .menu-button-container {
            display: flex;
            justify-content: center;
            margin-left: auto;

        }

        .menu {
            position: absolute;
            top: 0;
            margin-top: 70px;
            left: 0;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        #menu-toggle~.menu li {
            height: 0;
            margin: 0px;
            padding: 0;
            border: 0;
            transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        #menu-toggle:checked~.menu li {
            height: 2.5em;
            padding: 0.5em;
            transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        .menu>li {
            display: flex;
            justify-content: center;
            margin: auto;
            padding: 0.5em 0;
            width: 100%;
            color: rgb(0, 0, 0);
            background-color: rgb(255, 255, 255);
            opacity: 0.8;
        }

        .menu>li:not(:last-child) {
            border-bottom: 1px solid #000000;
        }
    }
</style>