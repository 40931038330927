<template >
    <section class="container mt-lg-5">
        <div class="row pt-5 px-5">
            <div class="col-12 d-flex justify-content-center justify-content-lg-start">
                <div class="services-text border-left-info px-3 py-2">
                    <span class="description-md montserrat-semibold">
                        SERVICIOS
                    </span>
                </div>    
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-8 flex-center">
                <div class="services-div-images">
                    <img :src="img1" 
                        class="img-fluid img1"
                        alt="main-m" />

                    <img :src="img2"
                        class="img-fluid img2" 
                        alt="main-m2" />
                </div>
            </div>

            <div class="col-12 col-lg-4 mb-lg-4 flex-center-start">
                <div class="services-div-circle">
                    <img :src="img3"
                        class="img-fluid rounded-circle img3"
                        alt="main-m3" />
                </div>
            </div>
        </div>
    </section>

    <div class="container cont-services2 p-0 px-lg-5 mt-lg-0">
        <div class="row mt-5 mb-4">
            <div class="col-12 text-center">
                <span class="title-xxsm text-info montserrat-semibold">
                    Nuestros servicios
                </span>
                <br>
                <span class="description-lg montserrat-semibold">
                    SERVICIOS VIP
                </span>
            </div>
        </div>
        <div class="row justify-content-center ">
            <div v-for="(listaVIP, index) in listasVIP" :key="index" 
                class="col-12 col-md-6 col-lg-4 mb-3">
                    <li-card :img="listaVIP.image && /\.(jpeg|jpg|webp|jp2|gif|png)$/.test(listaVIP.image) ? listaVIP.image : cards[index].img"
                        :title="listaVIP.title"
                        :text="listaVIP.short_description"
                        :description="listaVIP.description ? listaVIP.description : listaVIP.short_description">
                        <li-modal :title="listaVIP.title"
                            :price="listaVIP.price !== null && listaVIP.price !== 0 && listaVIP.price !== '' ? ('$' + listaVIP.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : ''"
                            :description="listaVIP.description ? listaVIP.description : listaVIP.short_description">
                        </li-modal>
                    </li-card>
            </div>
        </div>
    </div>

    <section class="container p-5 mb-4">
        <li-experience-card variant="info" 
            :photo_person="img_person"
            name_person="Lorena Fuentes"
            job_person="Deportista Profecional"
            comment_person="Increíble! He ido a la radiofrecuencia facial y la recomiendo ampliamente,
             ahora pruebo el área de spa y estoy encantada lo recomiendo, super atención y buenísima la terapeuta.">            
        </li-experience-card>
    </section>

</template>

<script setup>
    // Images
    const img_1 = require("ASSETS/images/services/Card1");
    const img_2 = require("ASSETS/images/services/Card2");
    const img_3 = require("ASSETS/images/services/Card3");
    const img_4 = require("ASSETS/images/services/Card4");
    const img_5 = require("ASSETS/images/services/Card5");
    const img_6 = require("ASSETS/images/services/Card6");

    const img1 = require("ASSETS/images/services/ServicesMn1");
    const img2 = require("ASSETS/images/services/ServicesMn2");
    const img3 = require("ASSETS/images/services/ServicesMn3");

    const img_person = require("ASSETS/images/services/Experience1");

    const check = require("ASSETS/images/services/check");
    

    // Vue Imports
    import { ref } from 'vue';

    // Variable
    const showModal = ref(false);

    const cards = ref([
        { img: img_5},
        { img: img_1},
        { img: img_3},
        { img: img_6},
        { img: img_2},
        { img: img_4},
        { img: img_5},
        { img: img_1},
        { img: img_3},
        { img: img_6},
        { img: img_2},
        { img: img_4},
    ]);

    const listasVIP = ref([])  
 
    const apiUrlVIP = 'https://api-gl.monocilindrero.co/page/prices/get_prices?section=services_vip';
    const authToken = 'ba+BldFH/xgFGmIV+Z4+Ry94zz1VM8S0lafeRLdxZZYmGygLiNMmwMc+9yxDQrQ+X0gbDg5ke0ZCc6up5W5Q6g==';
    
    const requestOptions = {
    method: 'GET',
    headers: {
        "reference": "com.grupolive.system",
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
    },
    };

    fetch(apiUrlVIP, requestOptions)
    .then(response => {
        if (!response.ok) {
        throw new Error('La solicitud no fue exitosa');
        }
        return response.json();
    })
    .then(datamn => {
        console.log('Datos:', datamn);

        // variable 
        listasVIP.value = datamn.data.list;
    })
    .catch(error => {
        console.error('Error al consumir la API:', error);
    });


</script>
<style lang="scss" scoped>
    @import './../assets/scss/minxin.scss';

    .services-div-images {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include upPixels(992px) {
            position: relative;
            width: 100%; 
            height: 100%;
            max-width: 750px;
        }

        img {
            padding-bottom: 24px;

            @include upPixels(992px) {
                position: absolute;
                padding-bottom: 0px;
            }
        }

        .img1 {
            padding-top: 24px;

            left: 0; 
            bottom: 0;
            z-index: 2;

            @include upPixels(992px) {
                padding-top: 0px;
            }
        }

        .img2 {
            top: 0;
            right: 0; 
            z-index: 1;
        }
    }

    .services-div-circle {
        @include upPixels(992px) {
            height: 482px;
        }

        .img3 {
            @include upPixels(992px) {
                margin-top: -60px;
            }
        }
    }
    
</style>